import {ObjectBaseInfo} from "../../../../types/object";
import {Box, Typography} from "@mui/material";
import React, {Fragment} from "react";

type YouTubeVideosProps = {
  object: ObjectBaseInfo;
}

const YouTubeVideos = (props: YouTubeVideosProps) => {
  const {object} = props;
  const patterns = [
    /https:\/\/youtu\.be\/([A-Za-z0-9-_]{11})/,
    /https:\/\/www\.youtube\.com\/watch\?v=([A-Za-z0-9-_]{11})/
  ]
  const videoIds = [];
  object.links.forEach(l => {
    let videoId = null;
    patterns.forEach(p => {
      const match = l.sURL.match(p);
      match && match[1] && videoIds.push(match[1])
    })
  })

  if (!videoIds) {
    return null;
  }

  return (
    <Fragment>
      {videoIds.length > 0 && (
        <Fragment>
          <div className="youTubeVideo--title">
            VIDEO
          </div>
          <Box
            className="youTubeVideo--wrapper"
          >
            {videoIds.map(id => (
              <Box
                className="youTubeVideo"
                sx={{
                  position: "relative",
                  paddingTop: "56.25%",
                  marginBottom: "1rem"
                }}
              >
                <iframe
                  className="youTubeVideo--video"
                  src={`https://www.youtube.com/embed/${id}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Box>
            ))}
          </Box>
        </Fragment>
      )}
    </Fragment>
  )
}

export default YouTubeVideos;
