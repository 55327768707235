import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BlackButton from '../../../atoms/BlackButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import dynamic from 'next/dynamic';
import ContactForm from '../../../molecules/ContactForm4Ad';
import Link from 'next/link';
import { connect } from 'react-redux';
import Accordion from '../../../molecules/Accordion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import numberWithSpaces from '../../../../utils/numberWithSpaces';
import useWidth from '../../../../utils/useWidth';
import scrollToAnchor from '../../../../utils/scrollToAnchor';
import Head from 'next/head';
import {
  srcset,
  getObjectHousingAssociation,
  getObjectArea,
  getObjectCostPerYear,
  getObjectDocuments,
  getAccordionData,
  getObjectSummary,
  getImportantInformation,
  getObjectBuilding,
  getObjectRealEstate,
  swedishDay,
  swedishMonth
} from "./houseHelpers";
import YouTubeVideos from "./YouTubeVideos";

const menuItem = (name: string, id: string) => (
  <li style={{ display: 'flex', alignItems: 'center' }}>
    <AnchorLink
      offset="45"
      href={`#${id}`}
      style={{ textDecoration: 'none', color: 'black' }}
    >
      <Typography
        component="p"
        sx={{
          fontSize: '14px',
          fontFamily: 'Roboto-Light',
          lineHeight: '18px',
          textTransform: 'uppercase',
          textDecoration: 'none',
          px: '5px',
        }}
      >
        {name}
      </Typography>
      <Box
        sx={{ width: '100%', height: '2px', backgroundColor: 'darkgreen' }}
        className="current-bar"
      />
    </AnchorLink>
  </li>
);

const HouseInfo = (props) => {
  const width = useWidth();

  const {
    associationTypes,
    coaAssociationTypes,
    municipalities,
    houseTypes,
    biddingInfo,
  } = props;

  const objectData = props.data;

  const objectImages = [...objectData.images];
  objectImages.sort((a, b) => {
    if (a.Type < b.Type) return -1;
    if (a.Type > b.Type) return 1;
    if (a.ImageOrder < b.ImageOrder) return -1;
    if (a.ImageOrder > b.ImageOrder) return 1;
    return 0;
  });

  const [bidding, setBidding] = React.useState(null);
  const [demoBids, setDemoBids] = React.useState([]);

  const [selectedMenu, setSelectedMenu] = React.useState(-1);
  const refObjectGeneral = React.useRef(null);
  const [showObjectGeneral, setShowObjectGeneral] = React.useState(false);
  const [imagesModalOpen, setImagesModalOpen] = React.useState(0);

  const [contactRadio, setContactRadio] = React.useState('2');
  const [contactMessage, setContactMessage] = React.useState('');
  const [bidsOpened, setBidsOpened] = React.useState(false);
  const [bidsModal, setBidsModal] = React.useState(false);
  const [biddingModal, setBiddingModal] = React.useState(false);

  const refContainer = React.useRef();
  React.useEffect(() => {
    scrollToAnchor();
    const res = biddingInfo.find((bid) => bid.id === objectData.BaseID);
    if (res === undefined) {
      setBidding({
        bids: [],
        highestBid: null,
        biddingOngoing: false,
        allowOnlineBidding: false,
        nBidStatusId: -1,
        bStopped: false,
      });
      setDemoBids([]);
    } else {
      setBidding(res);
      setDemoBids(
        !res || !res.bids || res.bids.length === 0
          ? []
          : res.bids
              .map((item) => {
                return {
                  price: item.nAmount,
                  date: new Date(item.dtPlacedAt),
                  bidder: item.nBidderNumber,
                  nBidStatusId: item.nBidStatusId ?? -1,
                  bStopped: item.bStopped ?? false,
                };
              })
              .sort((a, b) => (a.price < b.price ? 1 : -1))
      );
    }
  }, [objectData]);

  const objectSummary = getObjectSummary(houseTypes, objectData, coaAssociationTypes, associationTypes);
  let objectRooms = [...objectData.rooms];
  objectRooms = objectRooms
    .sort((a, b) => (a.nSortOrder > b.nSortOrder ? 1 : -1))
    .map((item) => {
      return {
        name: item.sName,
        desc: item.sDescription,
      };
    });
  const objectBuilding = getObjectBuilding(houseTypes, objectData);
  const objectRealEstate = getObjectRealEstate(objectData, coaAssociationTypes, associationTypes);
  const objectHousingAssociation = getObjectHousingAssociation(objectData);
  const objectArea = getObjectArea(objectData);
  const objectCostPerYear = getObjectCostPerYear(objectData);

  const objectDocuments = getObjectDocuments(objectData);

  const importantInformation = getImportantInformation(objectData);

  const accordionData = getAccordionData(objectSummary, objectData, objectRooms, objectBuilding, objectRealEstate, objectHousingAssociation, objectArea, objectCostPerYear, objectDocuments, importantInformation);
  const itemData = [
    {
      img: objectImages[1]?.FileURL,
      title: 'Image 1',
      cols: 1,
      rows: 1,
    },
    {
      img: objectImages[2]?.FileURL,
      title: 'Image 2',
      cols: 1,
      rows: 1,
    },
    {
      img: objectImages[3]?.FileURL,
      title: 'Image 3',
      cols: 1,
      rows: 1,
    },
    {
      img: objectImages[0]?.FileURL,
      title: 'Image 4',
      cols: 2,
      rows: 2,
    },
    {
      img: objectImages[4]?.FileURL,
      title: 'Image 5',
    },
    {
      img: objectImages[5]?.FileURL,
      title: 'Image 6',
    },
  ];

  const objectGeneral = [
    {
      type: 'KOMMUN',
      value:
        municipalities.find(
          (municipality) =>
            municipality.MunicipalityID === objectData.MunicipalityID
        ).Name ?? '',
    },
    {
      type: 'OMRÅDE',
      value: objectData.AreaName ?? '',
    },
    {
      type: 'BYGGÅR',
      value: objectData.ConstructionYear ?? '',
    },
    {
      type: 'Upplåtelseform',
      value:
        objectData.AssociationTypeID === null
          ? null
          : objectData.TypeID === 0
          ? coaAssociationTypes.find(
              (f) => f.AssociationTypeID === objectData.AssociationTypeID
            ).Type ?? null
          : associationTypes.find(
              (f) => f.TypeId === objectData.AssociationTypeID
            ).TypeName ?? null,
    },
    {
      type: 'TYP',
      value:
        houseTypes.find((type) => type.HouseTypeID === objectData.HouseTypeId)
          .Type ?? '',
    },
    {
      type: 'Boarea',
      value: objectData.Area ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative', mr: 2 }}>
            {`${objectData.Area} m `}
            <sup style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
              2
            </sup>
          </Box>
        </Box>
      ) : (
        ''
      ),
    },
    {
      type: 'Biarea',
      value: objectData.BiArea ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative', mr: 2 }}>
            {`${objectData.BiArea} m `}
            <sup style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
              2
            </sup>
          </Box>
        </Box>
      ) : (
        ''
      ),
    },
    {
      type: 'rum',
      value: objectData.NumberOfRooms ? objectData.NumberOfRooms + ' rum' : '',
    },
    {
      type: 'Månadsavgift',
      value: objectData.MonthlyFee
        ? numberWithSpaces(objectData.MonthlyFee) + ' kr'
        : '',
    },
    {
      type: 'Våning',
      value:
        objectData.FloorNumber && objectData.FloorTotal
          ? objectData.FloorNumber +
            ' av ' +
            objectData.FloorTotal +
            (objectData.Elevator ? ' (hiss finns)' : '')
          : '',
    },
    {
      type: 'tomtarea',
      value: objectData.LotArea ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative' }}>
            {`${objectData.LotArea} m `}
            <sup style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
              2
            </sup>
          </Box>
        </Box>
      ) : (
        ''
      ),
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactRadio((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setImagesModalOpen(0);
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    if (selectedMenu === index) setSelectedMenu(-1);
    else setSelectedMenu(index);
  };
  const MapWithNoSSR = dynamic(() => import('../map'), {
    ssr: false,
  });

  const emailShareSubject = encodeURIComponent(
    `${objectData.Address}, ${objectData.PostalAddress} - Privatmäklaren`
  );
  const emailShareBody = encodeURIComponent(
    `Hej,\nJag vill tipsa dig om en bostad som är till salu på ${
      objectData.Address
    }, ${
      objectData.PostalAddress
    } hos Privatmäklaren.\nHär kan du läsa mera: \n${
      process.env.NEXT_PUBLIC_DOMAIN_URL + '/' + objectData.Slug
    }`
  );

  const map = (
    <Box id="karta-mobile">
      <Typography
        sx={{
          fontFamily: 'Roboto-Regular',
          fontSize: '18px',
          textTransform: 'uppercase',
          marginTop: '30px',
          marginLeft: '20px',
        }}
      >
        Karta
      </Typography>
      {objectData.Latitude === '' || objectData.Longitude === '' ? (
        <>
          <Typography
            sx={{
              paddingLeft: '20px',
              paddingTop: '20px',
            }}
          >
            Kartan kunde inte laddas.
          </Typography>
        </>
      ) : (
        <Box sx={{ marginTop: '20px', height: '90vh' }}>
          <MapWithNoSSR
            latitude={objectData.Latitude}
            longitude={objectData.Longitude}
          />
        </Box>
      )}
    </Box>
  );

  const houseType = houseTypes.find(
    (ht) => ht.HouseTypeID === objectData.HouseTypeId
  ).Type;
  let pageTitle;
  switch (objectData.TypeID) {
    case 0:
      pageTitle = `${houseType} med ${objectData.NumberOfRooms} rok, ${objectData.Address}, ${objectData.AreaName}`;
      break;
    case 1:
      switch (objectData.HouseTypeId) {
        case 11:
          pageTitle = `${houseType} ${objectData.LotArea} m², ${objectData.Address}, ${objectData.AreaName}`;
          break;
        default:
          pageTitle = `${houseType} med ${objectData.NumberOfRooms} rum, ${objectData.Address}, ${objectData.AreaName}`;
          break;
      }
  }

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta
          property="og:image"
          content={`https://static2.privatmaklaren.se/previews/${objectData.BaseID}.jpg`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={objectData.BriefDescription} />
      </Head>
      <Box
        sx={{
          width: '100%',
          height: '300px',

          backgroundImage: `url('${objectImages[0]?.FileURL}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }}
      >
        {objectData.Status === 5 && (
          <Box sx={{ position: 'absolute' }}>
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '5px 21px',
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(49, 57, 76)',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  pt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                KOMMANDE
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Scrollspy
        items={['bilder-mobile', 'planritning-mobile', 'fakta-mobile', 'karta-mobile', 'kontakt-mobile']}
        currentClassName="is-current"
        style={{
          top: '0px',
          position: 'sticky',
          zIndex: '1999',
          display: 'flex',
          height: '45px',
          width: '100%',
          backgroundColor: '#E5E5E5',
          justifyContent: 'space-around',
          alignItems: 'center',
          overflow: 'auto',
          padding: '0px',
          paddingLeft: '10px',
          paddingRight: '10px',
          margin: '0px',
        }}
      >
        {![7, 9].includes(objectData.Status) && menuItem('BILDER', 'bilder-mobile')}
        {![7, 9].includes(objectData.Status) &&
          menuItem('planritning', 'planritning-mobile')}
        {![7, 9].includes(objectData.Status) && menuItem('Fakta', 'fakta-mobile')}
        {menuItem('Karta', 'karta-mobile')}
        {menuItem('Kontakt', 'kontakt-mobile')}
      </Scrollspy>

      <Box>
        <Box
          p={3}
          m={0}
          sx={{
            display: 'flex',
            backgroundColor:
              bidding?.biddingOngoing === true ? '#9CC5AC' : '#e6ede6',
          }}
        >
          {bidding?.biddingOngoing === true ? (
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: '18px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                }}
              >
                {bidding?.biddingOngoing === true &&
                  (bidding.highestBid === null
                    ? 'Budgivning Pågår'
                    : 'HÖGSTA BUD')}
              </Typography>
              <Typography
                sx={{
                  marginTop: '5px',
                  fontFamily: 'Roboto-Bold',
                  fontSize: '18px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                }}
              >
                {bidding &&
                  bidding?.highestBid !== null &&
                  numberWithSpaces(bidding.highestBid) + ' kr'}
              </Typography>
              <Typography
                sx={{
                  marginTop: '5px',
                  fontFamily: 'Avenir',
                  fontSize: '16px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  color: '#2F2E41',
                }}
              >
                {`utgångsPris ${numberWithSpaces(objectData.Price)} kr`}
              </Typography>
              {demoBids.length > 0 && (
                <Typography
                  sx={{
                    marginTop: '10px',
                    fontFamily: 'Roboto-Light',
                    fontSize: '16px',
                    lineHeight: '25px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setBidsOpened(!bidsOpened)}
                >
                  {`Se fler bud >`}
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              {objectData.Status === 9 && (
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '24px',
                    lineHeight: '25px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  Såld
                </Typography>
              )}
              {objectData.Status === 7 && (
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '24px',
                    lineHeight: '25px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  Såld eller nedtagen på säljarens begäran
                </Typography>
              )}
              {![7, 9].includes(objectData.Status) && (
                <>
                  <Box>
                    {objectData.Price ? (
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Avenir',
                            fontSize: '18px',
                            lineHeight: '25px',
                            textTransform: 'uppercase',
                            color: '#2F2E41',
                          }}
                        >
                          utgångsPris
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: '20px',
                            fontFamily: 'Avenir-Bold',
                            fontSize: '23px',
                            lineHeight: '25px',
                            textTransform: 'uppercase',
                            color: '#2F2E41',
                          }}
                        >
                          {numberWithSpaces(objectData.Price ?? 0)} kr
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Avenir',
                            fontSize: '24px',
                            lineHeight: '25px',
                            textTransform: 'uppercase',
                            color: '#2F2E41',
                          }}
                        >
                          Pris saknas
                        </Typography>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
          {objectData.IsBroker === 1 && (
            <>
              {bidding?.allowOnlineBidding && (
                <Box
                  sx={{ marginLeft: 'auto' }}
                  onClick={() => setBiddingModal(true)}
                >
                  <BlackButton fontSize={"15px"} fontFamily="Lato" height="36px" name="Lägg bud" />
                </Box>
              )}
              <Modal
                open={biddingModal}
                onClose={() => setBiddingModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                sx={{
                  zIndex: '2700',
                }}
              >
                <Fade in={biddingModal}>
                  <Box
                    sx={{
                      height: '100vh',
                      overflow: 'auto',
                      zIndex: '2900',
                      position: 'relative',
                      width: '100%',
                      bgcolor: 'background.paper',
                      border: '0px solid #000',
                      boxShadow: 24,

                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      backgroundColor: ' rgb(227, 237, 228)',

                      outlineWidth: 0,
                    }}
                  >
                    <Box
                      sx={{
                        px: 4,
                        display: 'flex',
                        position: 'sticky',
                        top: '0px',
                        pt: '15px',
                        pb: '15px',
                        width: '100%',
                        backgroundColor: ' rgb(227, 237, 228)',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Roboto-Bold',
                          fontSize: '25px',
                        }}
                      >
                        Bud
                      </Typography>
                      <CloseIcon
                        sx={{ ml: 'auto', cursor: 'pointer' }}
                        onClick={() => setBiddingModal(false)}
                      />
                    </Box>
                    <iframe
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      src={`${process.env.NEXT_PUBLIC_BIDDING_IFRAME_URL}/${objectData.BaseID}`}
                    />
                  </Box>
                </Fade>
              </Modal>
            </>
          )}
        </Box>
        {!bidding?.biddingOngoing && (
          <>
            <Divider sx={{ backgroundColor: '#515151' }} />
          </>
        )}
        <Box
          px={3}
          m={0}
          sx={{
            overflow: 'hidden',
            backgroundColor: '#9CC5AC',
            maxHeight:
              bidsOpened && refContainer.current !== null
                ? `${(refContainer.current as any).offsetHeight + 80}px`
                : '0px',
          }}
        >
          <Box ref={refContainer}>
            <TableContainer
              sx={{
                backgroundColor: '#9CC5AC',
                border: '0px',
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Bud</TableCell>
                    <TableCell>Datum</TableCell>
                    <TableCell align="right">Budgivare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {demoBids
                    .slice(0, demoBids.length < 5 ? demoBids.length : 5)
                    .map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          textDecoration:
                            row.nBidStatusId === 2 ? 'line-through' : 'none',
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {numberWithSpaces(row.price) + ' KR'}
                        </TableCell>
                        <TableCell padding="normal">{`${row.date.getDate()}/${
                          row.date.getMonth() + 1
                        } ${
                          row.date.getHours() > 9
                            ? row.date.getHours()
                            : '0' + row.date.getHours()
                        }:${
                          row.date.getMinutes() > 9
                            ? row.date.getMinutes()
                            : '0' + row.date.getMinutes()
                        }`}</TableCell>
                        <TableCell align="right">
                          {row.bidder +
                            (row.bStopped
                              ? ' (stannat)'
                              : '' +
                                (row.nBidStatusId === 2
                                  ? ' (återkallat)'
                                  : ''))}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {demoBids.length > 5 && (
              <Typography
                sx={{
                  fontFamily: 'Avenir',
                  fontSize: '18px',
                  lineHeight: '25px',
                  textDecoration: 'underline',
                  color: '#2F2E41',
                  cursor: 'pointer',
                  textAlign: 'center',
                  marginBottom: '30px',
                }}
                onClick={() => setBidsModal(true)}
              >
                {`Visa hela historiken på ${demoBids.length} bud >`}
              </Typography>
            )}
          </Box>
        </Box>
        <Modal
          open={bidsModal}
          onClose={() => setBidsModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            zIndex: '1700',
          }}
        >
          <Fade in={bidsModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                height: '500px',
                overflowY: 'scroll',
                boxShadow: 24,
                backgroundColor: '#9CC5AC',
                p: 4,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: 'black',
                  }}
                >
                  Budhistorik
                </Typography>
                <Box
                  sx={{
                    ml: 'auto',
                    fontFamily: 'Avenir',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                  onClick={() => setBidsModal(false)}
                >
                  <CloseIcon />
                </Box>
              </Box>

              <TableContainer
                sx={{
                  border: '0px',
                  color: 'white',
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Bud</TableCell>
                      <TableCell>Datum</TableCell>
                      <TableCell align="right">Budgivare</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demoBids.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          textDecoration:
                            row.nBidStatusId === 2 ? 'line-through' : 'none',
                          textDecorationColor: 'black',
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {numberWithSpaces(row.price)}
                        </TableCell>
                        <TableCell>{`${row.date.getDate()}/${
                          row.date.getMonth() + 1
                        } ${('0'+row.date.getHours()).slice(-2)}:${('0'+row.date.getMinutes()).slice(-2)}`}</TableCell>
                        <TableCell align="right">{row.bidder +
                          (row.bStopped ? ' (stannat)' : '') +
                          (row.nBidStatusId === 2 ? ' (återkallat)' : '')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Fade>
        </Modal>

        <Box p={0} sx={{ backgroundColor: '#e6ede6' }}>
          {objectData.IsBroker === 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box p={3} sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir',
                    fontSize: '18px',
                    lineHeight: '20px',
                    textTransform: 'uppercase',
                    color: '#2F2E41',
                  }}
                >
                  SÄLJES UTAN MÄKLARE
                </Typography>
                <Link href="/salja#annons">
                  (Läs mer om vår Annonstjänst här)
                </Link>
                <Typography
                  sx={{
                    marginTop: '10px',
                    fontFamily: 'Lato',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#2F2E41',
                  }}
                >
                  {objectData.seller.firstName +
                    ' ' +
                    objectData.seller.lastName}
                </Typography>

                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <AnchorLink
                      href="#kontakt-mobile"
                      offset={45}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlackButton
                        fontFamily="Lato"
                        height="36px"
                        fontSize="14px"
                        name="Kontakta"
                      />
                    </AnchorLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {objectData.broker && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                height="200px"
                width="200px"
                sx={{
                  backgroundImage: `url('https://static2.privatmaklaren.se/brokers/${objectData.broker.nBrokerId}.jpg')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
              <Box p={3} sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: '18px',
                    lineHeight: '25px',
                    textTransform: 'uppercase',
                  }}
                >
                  {objectData.broker.strBrokerText}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '5px',
                    marginBottom: '10px',
                    fontFamily: 'Roboto-Regular',
                    fontSize: '16px',
                    lineHeight: '25px',
                  }}
                >
                  {objectData.broker.strBrokerName}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ marginLeft: 'auto' }}>
                    <AnchorLink
                      href="#kontakt-mobile"
                      offset={45}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlackButton
                        fontFamily="Lato"
                        width="115px"
                        height="36px"
                        fontSize="15px"
                        name="Kontakta"
                      />
                    </AnchorLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Divider sx={{ background: 'black', height: '1px' }} />

        {![7, 9].includes(objectData.Status) && (
          <>
            {objectData.showings.length > 0 ? (
              <>
                <Divider sx={{ background: 'black', height: '2px' }} />
                <Box p={3} sx={{ backgroundColor: '#e6ede6' }}>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular',
                        fontSize: '18px',
                        lineHeight: '25px',
                        textTransform: 'uppercase',
                      }}
                    >
                      Visning
                    </Typography>
                    {[...objectData.showings]
                      .sort((a, b) => (a.TimeStart > b.TimeStart ? 1 : -1))
                      .filter((item) => {
                        const timeEnd = new Date(item.TimeEnd);
                        if (timeEnd > new Date()) return 1;
                        return -1;
                      })
                      .map((item, i) => {
                        const startTime = new Date(item.TimeStart);
                        const endTime = new Date(item.TimeEnd);

                        return (
                          <Box
                            key={i}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '20px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                color: '#2F2E41',
                              }}
                            >
                              {swedishDay(startTime.getDay()) +
                                ' ' +
                                startTime.getDate() +
                                ' ' +
                                swedishMonth(startTime.getMonth()) +
                                ' kl. ' +
                                ('0' + startTime.getHours()).slice(-2) +
                                '.' +
                                ('0' + startTime.getMinutes()).slice(-2) +
                                '-' +
                                ('0' + endTime.getHours()).slice(-2) +
                                '.' +
                                ('0' + endTime.getMinutes()).slice(-2)}
                            </Typography>

                            <Box
                              sx={{ marginLeft: 'auto' }}
                              onClick={() =>
                                setContactMessage(
                                  `Jag önskar komma på visningen ${
                                    swedishDay(startTime.getDay()) +
                                    ' ' +
                                    startTime.getDate() +
                                    ' ' +
                                    swedishMonth(startTime.getMonth()) +
                                    ' kl. ' +
                                    ('0' + startTime.getHours()).slice(-2) +
                                    '.' +
                                    ('0' + startTime.getMinutes()).slice(-2) +
                                    '-' +
                                    ('0' + endTime.getHours()).slice(-2) +
                                    '.' +
                                    ('0' + endTime.getMinutes()).slice(-2)
                                  }.`
                                )
                              }
                            >
                              <AnchorLink
                                href="#kontakt-mobile"
                                offset={45}
                                style={{ textDecoration: 'none' }}
                              >
                                <BlackButton
                                  fontFamily="Lato"
                                  width="108px"
                                  height="36px"
                                  fontSize="15px"
                                  name="Boka"
                                />
                              </AnchorLink>
                            </Box>
                          </Box>
                        );
                      })}
                    {objectData.ShowingComment && (
                      <Typography
                        sx={{
                          marginTop: '20px',
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                        }}
                      >
                        {objectData.ShowingComment}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    px: 3,
                    py: 2,
                    backgroundColor: '#e6ede6',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      color: '#2F2E41',
                    }}
                  >
                    {objectData.ShowingComment
                      ? objectData.ShowingComment
                      : 'Hos Privatmäklaren visar ägaren själv. Boka visning här. '}
                  </Typography>

                  <Box
                    sx={{ marginLeft: 'auto' }}
                    onClick={() =>
                      setContactMessage(`Intresseanmälan för visning`)
                    }
                  >
                    <AnchorLink
                      href="#kontakt-mobile"
                      offset={45}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlackButton
                        fontFamily="Lato"
                        width="115px"
                        height="36px"
                        fontSize="15px"
                        name="Boka"
                      />
                    </AnchorLink>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        <Divider sx={{ background: 'black', height: '2px' }} />
        <Box
          sx={{
            display: 'flex',
            height: '54px',
            alignItems: 'center',
            backgroundColor: '#e6ede6',
          }}
        >
          <Typography
            sx={{
              marginTop: '10px',
              marginLeft: '15px',
              fontFamily: 'Roboto-Regular',
              fontSize: '18px',
              lineHeight: '25px',
            }}
          >
            DELA
          </Typography>
          <a
            rel="noreferrer"
            href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
              process.env.NEXT_PUBLIC_DOMAIN_URL + '/' + objectData.Slug
            )}`}
            target="_blank"
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          >
            <Box component="img" src={'/images/footerFBB.svg'} ml="20px" />
          </a>

          <a
            rel="noreferrer"
            href={`mailto:?subject=${emailShareSubject}&body=${emailShareBody}`}
            target={'_blank'}
          >
            <Box component="img" src={'/images/shareEmailB.svg'} ml="20px" />
          </a>
        </Box>
        {![7, 9].includes(objectData.Status) && (
          <Box
            sx={{
              mt: '20px',
              height: showObjectGeneral
                ? `${Math.max(
                    refObjectGeneral.current.offsetHeight + 20,
                    700
                  )}px`
                : '700px',
              // height: '700px',
              px: '15px',
              width: '100%',
              overflow: 'hidden',
              position: 'relative',
              transition: '0.2s all ease-in-out',
            }}
          >
            {refObjectGeneral.current !== null &&
              refObjectGeneral.current.offsetHeight > 700 &&
              !showObjectGeneral && (
                <Box
                  className="cross-browser-height-1"
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    background: 'linear-gradient(#fff0 70%, #fff 96%)',
                    opacity: showObjectGeneral ? 0 : 1,
                    display: 'flex',
                    alignItems: 'flex-end',
                    transition: '.2s all ease-in-out',
                  }}
                >
                  {!showObjectGeneral && (
                    <Typography
                      component="span"
                      sx={{
                        marginTop: '16px',
                        fontFamily: 'Avenir',
                        fontSize: '14px',
                        lineHeight: '25px',
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        display: 'block'
                      }}
                      onClick={() => setShowObjectGeneral(!showObjectGeneral)}
                    >
                      {`Hela Beskrivningen >`}
                    </Typography>
                  )}
                </Box>
              )}
            <Box ref={refObjectGeneral}>
              <Typography
                component="span"
                sx={{
                  marginTop: '10px',
                  fontFamily: 'Avenir-Bold',
                  fontSize: '20px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  display: 'block'
                }}
              >
                {objectData.Address}
              </Typography>
              <Box sx={{ mt: '8px', display: 'flex' }}>
                <Grid container>
                  {objectGeneral.map(
                    (item, i) =>
                      item.value !== null &&
                      item.value !== '' &&
                      item.value != undefined && (
                        <Grid item xs={6} key={i}>
                          <Box sx={{ mt: '17px' }}>
                            <Typography
                              component="span"
                              sx={{
                                fontFamily: 'Avenir',
                                fontSize: '14px',
                                lineHeight: '25px',
                                textTransform: 'uppercase',
                                color: '#2F2E41',
                                display: 'block'
                              }}
                            >
                              {item.type}
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                mt: '5px',
                                fontFamily: 'Avenir',
                                fontSize: '14px',
                                lineHeight: '25px',
                                color: '#2F2E41',
                                display: 'block'
                              }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </Box>

              <Typography
                component="span"
                sx={{
                  marginTop: '55px',
                  fontFamily: 'Avenir-Bold',
                  fontSize: '20px',
                  lineHeight: '25px',
                  textTransform: 'uppercase',
                  display: 'block'
                }}
              >
                {objectData.Headline}
              </Typography>
              <Typography
                component="span"
                sx={{
                  marginTop: '21px',
                  fontFamily: 'Lato-Light',
                  fontSize: '18px',
                  lineHeight: '25px',
                  whiteSpace: 'pre-wrap',
                  display: 'block'
                }}
              >
                {objectData.BriefDescription}
              </Typography>
            </Box>
          </Box>
        )}
        {[7, 9].includes(objectData.Status) && (
          <>
            <Typography
              sx={{
                marginTop: '10px',
                fontFamily: 'Avenir-Bold',
                fontSize: '20px',
                lineHeight: '25px',
                textTransform: 'uppercase',
              }}
            >
              {objectData.Address}
            </Typography>
            <Box sx={{ px: '15px', mt: '8px', display: 'flex' }}>
              <Grid container>
                {objectGeneral.map(
                  (item, i) =>
                    item.value !== null &&
                    item.value !== '' &&
                    item.value != undefined && (
                      <Grid item xs={6} key={i}>
                        <Box sx={{ mt: '17px' }}>
                          <Typography
                            sx={{
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              lineHeight: '25px',
                              textTransform: 'uppercase',
                              color: '#2F2E41',
                            }}
                          >
                            {item.type}
                          </Typography>
                          <Typography
                            sx={{
                              mt: '5px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              lineHeight: '25px',
                              color: '#2F2E41',
                            }}
                          >
                            {item.value}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>
            </Box>
          </>
        )}
        {objectImages.length !== 0 && ![7, 9].includes(objectData.Status) && (
          <Box sx={{ marginBottom: "1rem" }}>
            <ImageList
              id="bilder-mobile"
              sx={{ width: '100%', mt: '20px', cursor: 'pointer' }}
              variant="quilted"
              cols={3}
              rowHeight={width === 'md' ? 220 : width === 'sm' ? 180 : 120}
              onClick={() => setImagesModalOpen(1)}
            >
              {itemData.map((item, i) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  {item.img ? (
                    <img
                      {...srcset(item.img, 350, item.rows, item.cols)}
                      alt={item.title}
                      loading="lazy"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#DDD',
                      }}
                    ></Box>
                  )}
                  {i === 5 &&
                    objectImages.filter((i) => i.Type === 0).length > 6 && (
                      <Box
                        sx={{
                          top: 0,
                          backgroundColor: '#00000084',
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Roboto-Bold',
                            fontSize: '20px',
                            color: 'white',
                          }}
                        >
                          {'+ '}
                          {objectImages.filter((i) => i.Type === 0).length - 6}
                        </Typography>
                      </Box>
                    )}
                </ImageListItem>
              ))}
            </ImageList>
            <Modal
              open={imagesModalOpen !== 0}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              sx={{
                zIndex: '999999',
              }}
            >
              <Fade in={imagesModalOpen !== 0}>
                <Box
                  sx={{
                    height: '100vh',
                    overflow: 'auto',
                    zIndex: '999999',
                    position: 'relative',
                    width: '100%',
                    bgcolor: 'background.paper',
                    border: '0px solid #000',
                    backgroundColor: ' rgb(250, 246, 250)',
                    boxShadow: 24,
                    px: 4,
                    outlineWidth: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'sticky',
                      top: '0px',
                      pt: '30px',
                      bgcolor: 'rgb(250, 246, 250)',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Bold',
                        fontSize: '22px',
                      }}
                    >
                      {imagesModalOpen === 1 ? 'Bilder' : 'Planritningar'}
                    </Typography>
                    <CloseIcon
                      sx={{ ml: 'auto', cursor: 'pointer' }}
                      onClick={() => setImagesModalOpen(0)}
                    />
                  </Box>
                  {objectImages
                    .filter((i) => i.Type >= imagesModalOpen - 1)
                    .map((item, i) => (
                      <Box key={i}>
                        <Box
                          component="img"
                          src={item.FileURL + '?w=1024'}
                          alt={item.title}
                          width="100%"
                          loading="lazy"
                        />
                        <Typography
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            marginBottom: '10px',
                          }}
                        >
                          {item.ImageText}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Fade>
            </Modal>
          </Box>
        )}
        <Box
          sx={{
            margin: "0 1rem"
          }}
        >
          <YouTubeVideos object={objectData} />
        </Box>
      </Box>
      {![7, 9].includes(objectData.Status) && (
        <Box id="planritning-mobile">
          <Box px={2.5} sx={{ marginTop: '50px' }}>
            <Typography
              sx={{
                fontFamily: 'Roboto-Regular',
                fontSize: '18px',
                textTransform: 'uppercase',
                marginTop: '30px',
              }}
            >
              Planritning
            </Typography>
            {objectImages.find((i) => i.Type === 1) ? (
              <>
                <Grid container>
                  {objectImages
                    .filter((i) => i.Type === 1)
                    .map((item, i) => (
                      <Grid key={i} item xs={12} md={6} sx={{ p: 2 }}>
                        <img
                          src={item.FileURL + '?w=1024'}
                          alt="planritning"
                          style={{
                            marginTop: '14px',
                            width: '100%',
                            cursor: 'pointer',
                          }}
                          onClick={() => setImagesModalOpen(2)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: 14 }}>
                  Planritningar saknas
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
      {![7, 9].includes(objectData.Status) && (
        <Box id="fakta-mobile">
          <Box p={3} mt={'50px'}>
            <Typography
              sx={{
                fontFamily: 'Roboto-Bold',
                fontSize: '18px',
                lineHeight: '25px',
                textTransform: 'uppercase',
              }}
            >
              FAKTA
            </Typography>
          </Box>
          <Box px={4}>
            <Accordion data={accordionData} />
          </Box>
        </Box>
      )}

      {map}

      <Box id="kontakt-mobile">
        <ContactForm
          status={objectData.Status}
          baseId={objectData.BaseID}
          data={objectData.broker}
          message={contactMessage}
          seller={objectData.seller}
          tomt={objectData.TypeID === 11}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    associationTypes: state.main.associationTypes,

    coaAssociationTypes: state.main.coaAssociationTypes,

    municipalities: state.main.municipalities,

    houseTypes: state.main.houseTypes,

    biddingInfo: state.main.biddingInfo,
  };
};

export default connect(mapStateToProps, null)(HouseInfo);
